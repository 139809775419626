<template>
  <div class="view-men">
    <h3>特别提示：</h3>
    <p class="tex-indent">
      欢迎您注册焱十一用户！您在使用焱十一提供的服务之前，请您务必审慎阅读、充分理解本协议各条款内容，特别是以粗体标注的部分，包括但不限于免除或者限制责任的条款。如您不同意本服务协议及/或随时对其的修改，您可以主动停止使用焱十一提供的服务；您一旦使用焱十一提供的服务，即视为您已了解并完全同意本服务协议各项内容以及焱十一发出的各类公告的同意，包括焱十一对服务协议随时所做的任何修改，并成为我们的用户。
    </p>
    <h3>一、总则</h3>
    <p class="tex-indent">1.焱十一：指北京焱十一科技有限公司。</p>
    <p class="tex-indent">2.焱十一平台：由焱十一开发并运营的网络拍卖服务平台，包括的官方网站（www.easyebid.com）、APP、微信公众号/服务号/视频号、微博客户端、抖音号等。</p>
    <p class="tex-indent">
      3.焱十一服务：通过焱十一平台提供的网络拍卖服务，包括但不限于观看拍卖会直播、浏览拍卖会信息、查询拍卖图录、拍品信息及拍卖历史、参加境内外的直播竞拍、委托代拍、参加焱十一组织的线上及线下活动以及使用信息服务、技术服务等。尽管如此，您在注册成为焱十一用户后并非当然有权使用全部的焱十一服务。在参与某场拍卖活动前，您还须根据组织该场次拍卖活动的拍卖公司的规定和要求，注册该场次的拍卖活动，并遵守《焱十一服务规则》及拍卖公司的《拍卖规则》等。
    </p>
    <h3>二、帐号的注册与使用</h3>
    <p class="tex-indent">您使用焱十一服务时需要注册焱十一帐号，当您注册和使用焱十一帐号时应符合以下标准并遵守下述要求：</p>
    <p class="tex-indent">1. 具有完全民事行为能力的自然人且年龄在18周岁以上。</p>
    <p class="tex-indent">2. 对艺术品拍卖有基本的了解与风险认知，具有一定的收藏投资经验，并具有较高的风险识别能力和风险承受能力。</p>
    <p class="tex-indent">3. 购买国内外艺术品（包括但不限于其他拍卖标的）的资金为其自有合法资金，并且将该笔资金用于购买国内外艺术品（包括但不限于其他拍卖标的）不会违反任何法律法规及其他相关规定。</p>
    <p class="tex-indent">4. 熟悉网络拍卖流程，具有一定的网络操作能力和互联网应用能力。</p>
    <p class="tex-indent">5. 认真阅读并同意遵守本协议和《焱十一服务规则》，对焱十一的业务模式有充分并正确的理解。</p>
    <p class="tex-indent">6.
      用户注册成功后，焱十一将给予每个用户一个帐号，用户可以自主设置帐号密码。该用户帐号和密码由用户负责保管；用户应当对以其用户帐号进行的所有活动和事件负法律责任；如因用户个人原因导致账户泄漏从而给用户本人造成损失的，焱十一不承担任何责任，如因次给焱十一造成损失的，所有损失由用户承担。
    </p>
    <p class="tex-indent">7.
      焱十一帐号（即焱十一用户ID）的所有权归焱十一，您按注册页面引导填写信息，阅读并同意本协议且完成全部注册程序后，即可成为焱十一注册用户，并获得该焱十一帐号的使用权。您应提供及时、详尽及准确的个人资料，并不断更新注册资料，符合及时、详尽、准确的要求。所有原始键入的资料将引用为注册资料。如果因注册信息不真实或更新不及时而引发的相关问题，焱十一不负任何责任。您可以通过焱十一帐号设置页面查询、更正您的信息，焱十一帐号设置页面地址：https://easyebid.com/index.html#/personalcenter，您应当通过真实身份信息认证注册帐号，且您提交的帐号名称、头像和简介等注册信息中不得出现违法和不良信息，经焱十一审核，如存在上述情况，焱十一将不予注册；同时，在注册后，如发现您以虚假信息骗取帐号名称注册，或其帐号头像、简介等注册信息存在违法和不良信息的，焱十一有权不经通知单方采取限期改正、暂停使用、注销、收回等措施。
    </p>
    <p class="tex-indent">8.
      基于国家法律法规的要求或者焱十一服务政策的要求，您在使用某些焱十一服务时需要填写真实的姓名、身份证号等信息。若您填写的信息不完整、不正确，则可能无法使用相应服务或在使用过程中受到限制。</p>
    <p class="tex-indent">9.
      焱十一帐号包括帐户名称和密码，您可使用设置的帐户名称（包括用户名、手机号、邮箱）和密码登录；在帐号使用过程中，为了保障您的帐号安全基于不同的终端以及您的使用习惯，我们可能采取不同的验证措施识别您的身份。例如您的帐户在新设备首次登录，我们可能通过密码加校验码的方式识别您的身份，验证方式包括但不限于短信验证码、服务使用信息验证。
    </p>
    <p class="tex-indent">10.
      您不应将其帐号、密码转让、出售或出借予他人使用，帐号使用权仅属于初始申请注册人，您应对帐号内的全部行为独立承担全部责任。如因帐号转让、买卖或出借导致的帐号纠纷或焱十一无法判断帐号归属等情况，焱十一有权拒绝提供帐号找回，并视情况采取冻结帐号、封禁帐号等措施。
    </p>
    <p class="tex-indent">11.
      因您个人原因导致的帐号无法使用，如需找回焱十一帐号，请按照焱十一帐号找回要求提供相应的信息，并确保提供的信息合法真实有效，若提供的信息不符合要求或帐号的唯一凭证不再有效，无法通过焱十一安全验证，因存在帐号被盗等安全风险导致无法判断帐号归属，焱十一有权拒绝提供帐号找回服务，并视情况采取冻结帐号、封禁帐号等措施。您于焱十一帐号下绑定的手机号如因您个人原因停止使用，请尽快更改焱十一帐号的绑定手机，否则如遇运营商二次放号，二次放号的用户可以通过您原来的手机号进行验证登录，可查看到您的全部帐号信息及历史交易信息，您的焱十一帐号将无法继续使用；同时，您帐号内的个人信息有泄露的风险，帐号内的余额有被盗用的风险，请您务必及时更换绑定手机，如因未及时换绑手机号导致帐号丢失或个人信息泄露的责任由您自行承担，焱十一有权拒绝提供帐号找回服务，并视情况采取冻结帐号、封禁帐号等措施。
    </p>
    <p class="tex-indent">12. 在需要终止使用焱十一帐号服务时，符合以下条件的，您可以申请注销您的焱十一帐号。</p>
    <p class="tex-indent">（1）您仅能申请注销您本人的帐号，并依照焱十一的流程进行注销；</p>
    <p class="tex-indent">（2）您仍应对您在注销帐号前且使用焱十一服务期间的行为承担相应责任，同时焱十一仍可保存您注销前的相关信息；</p>
    <p class="tex-indent">（3）注销成功后，帐号信息、个人身份证信息、交易记录、会员权益等将无法恢复或提供；</p>
    <p class="tex-indent">（4）该账号下不存在竞拍中或未结算拍品，并且不存在任何形式的法律或经济纠纷。</p>
    <p class="tex-indent">13.
      为了防止资源占用，如您连续十二个月未使用您的焱十一帐号或未通过焱十一认可的其他方式登录过您的焱十一帐户，焱十一有权对该帐户进行注销，您将不能再通过该帐号登录名登录本网站或使用相关服务。如该帐户有待处理交易或余额，焱十一会在合理范围内协助您处理，请您按照焱十一提示的方式进行操作。
    </p>
    <p class="tex-indent">
      14.
      我们将按照焱十一的隐私政策（<router-link to='https://www.easyebid.com/index.html#/privacy'>
        https://www.easyebid.com/index.html#/privacy</router-link>
      ）使用您在注册焱十一帐户、使用焱十一服务中提交、留存的信息。您保证在您使用焱十一的所有服务前，已经充分了解并同意焱十一可以据此处理用户信息。</p>
    <p class="tex-indent">15.
      为了满足相关法律法规的要求和保障您的帐号安全，尤其是您在进行帐号绑定、解绑、注销等敏感操作时，我们会将您提交的个人身份信息发送至银行、运营商等机构进行验证查询，以核实您身份的真实性。</p>
    <p class="tex-indent">16.
      当用您使用一键登录功能时，运营商会将您的本机手机号传输给焱十一，用以焱十一判断您的手机号是否已注册焱十一帐号。如您的手机号已注册焱十一帐号，并通过安全验证，则您将无需填写手机号、验证码、帐户名、密码等，可直接完成帐号登录。如您不满足一键登录的要求，或者选取了其他登录方式，您可使用其他登录方式进行帐号登录。
    </p>
    <h3>三、使用规则</h3>
    <p class="tex-indent">
      1.您在使用焱十一的服务时，必须遵守《拍卖法》、《文物保护法》、《网络安全法》、《计算机信息网络国际联网安全保护管理办法》、《互联网信息服务管理办法》、等中华人民共和国相关法律法规的规定，您应同意将不会利用本服务进行任何违法或不正当的活动，包括但不限于下列行为:
    </p>
    <p class="tex-indent">（1）上载、展示、张贴、传播或以其它方式传送含有下列内容之一的信息：</p>
    <p class="tex-indent-o">1）反对宪法所确定的基本原则的；</p>
    <p class="tex-indent-o">2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
    <p class="tex-indent-o">3）损害国家荣誉和利益的；</p>
    <p class="tex-indent-o">4）煽动民族仇恨、民族歧视、破坏民族团结的；</p>
    <p class="tex-indent-o">5）破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
    <p class="tex-indent-o">6）散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
    <p class="tex-indent-o">7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
    <p class="tex-indent-o">8）侮辱或者诽谤他人，侵害他人合法权利的；</p>
    <p class="tex-indent-o">9）含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它道德上令人反感的内容；</p>
    <p class="tex-indent-o">10）含有中国法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的。</p>
    <p class="tex-indent">（2）不得为任何非法目的而使用焱十一服务；</p>
    <p class="tex-indent">（3）不得在焱十一平台从事以下活动：</p>
    <p class="tex-indent-o">1）危害计算机信息网络安全的行为；</p>
    <p class="tex-indent-o">2）未经允许，进入计算机信息网络或者使用计算机信息网络资源的；</p>
    <p class="tex-indent-o">3）发布法律禁止或限制拍卖/出售的标的，或涉嫌侵犯他人知识产权或其它合法权益的标的或服务信息；</p>
    <p class="tex-indent-o">4）进行对焱十一造成或可能造成（由焱十一自行酌情确定）重大负荷的任何行为；</p>
    <p class="tex-indent-o">5）干扰或试图干扰焱十一的正常工作和运营；</p>
    <p class="tex-indent-o">6）未经焱十一和第三方（如适用）事先明确书面允许，对焱十一的任何内容（除您提交的资料以外）进行复制、修改、制作衍生作品、分发或公开展示等；</p>
    <p class="tex-indent-o">7）擅自编辑、整理、编排焱十一及相关服务的信息内容后在焱十一及相关服务的源页面以外的渠道进行展示；</p>
    <p class="tex-indent-o">8）采用焱十一禁止或限制的任何形式进入焱十一的;</p>
    <p class="tex-indent-o">9）利用/通过焱十一的系统漏洞等来获得不正当利益;</p>
    <p class="tex-indent-o">10）使用/利用焱十一外挂获取不正当利益等。</p>
    <p class="tex-indent">2.
      您明确知晓，焱十一发布的大部分资料是焱十一专有的，或第三方许可给焱十一使用的。因此，您同意，未经焱十一明确书面准许，您不会为了任何目的使用任何机器人、蜘蛛软件、刷屏软件或其他自动方式进入焱十一。</p>
    <p class="tex-indent">3.
      您明确知晓，焱十一所展示的内容仅为一般信息，不应被当作焱十一对您竞买、购买任何拍品、商品的任何建议或意见。您并不依赖焱十一服务的内容，并不以此作为竞买、购买建议或意见。您应自行判断或在认为必要时听取专业顾问意见的基础后自行决定在焱十一上进行的任何交易是否恰当或适宜。
    </p>
    <p class="tex-indent">4.
      您若违反本协议或相关的服务条款的规定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，您同意赔偿焱十一与合作公司、关联公司，并使之免受损害。对此，焱十一有权视您的行为性质，采取包括但不限于暂停使用许可、终止服务、限制使用、回收焱十一帐号、追究法律责任等措施。对恶意注册焱十一帐号或利用焱十一帐号进行违法活动、捣乱、骚扰、欺骗其他用户以及其他违反本协议的行为，焱十一有权回收其帐号。同时，焱十一会视司法部门的要求，协助调查。
    </p>
    <p class="tex-indent">5. 您不得对本服务任何部分或本服务之使用或获得，进行复制、拷贝、出售、转售或用于任何其它商业目的。</p>
    <p class="tex-indent">6.
      您须对自己在使用焱十一服务过程中的行为承担法律责任。您承担法律责任的形式包括但不限于：对受到侵害者进行赔偿，以及在焱十一首先承担了因用户行为导致的行政处罚或侵权损害赔偿责任后，您应给予焱十一等额的赔偿。</p>
    <h3>四、服务内容</h3>
    <p class="tex-indent">1.
      为使用本服务，您必须能够自行经有法律资格对您提供互联网接入服务的第三方，进入国际互联网，并应自行支付相关服务费用。此外，您必须自行配备及负责与国际联网连线所需之一切必要装备，包括计算机、数据机或其它存取装置。</p>
    <p class="tex-indent">2. 鉴于网络服务的特殊性，用户同意焱十一有权不经事先通知，随时变更、中断或终止部分或全部的服务（包括收费网络服务）。焱十一不担保服务不会中断，对服务的及时性、安全性、准确性也都不作担保。
    </p>
    <p class="tex-indent">3. 免责声明：因以下情况造成焱十一服务在合理时间内的中断，焱十一无需为此承担任何责任：</p>
    <p class="tex-indent">（1）焱十一需要定期或不定期地对提供网络服务的平台或相关的设备进行检修或者维护，焱十一保留不经事先通知为维修保养、升级或其它目的暂停本服务任何部分的权利。</p>
    <p class="tex-indent">（2）因台风、地震、洪水、雷电或恐怖袭击等不可抗力原因；</p>
    <p class="tex-indent">（3）用户的电脑软硬件和通信线路、供电线路出现故障的；</p>
    <p class="tex-indent">（4）因病毒、木马、恶意程序攻击、网络拥堵、系统不稳定、系统或设备故障、通讯故障、电力故障、银行原因、第三方服务瑕疵或政府行为等原因。</p>
    <p class="tex-indent">尽管有前款约定，焱十一将采取合理行动积极促使服务恢复正常。</p>
    <p class="tex-indent">4. 焱十一以“按现状”和“可得到”的状态提供服务，不对焱十一服务所涉及的技术和信息作任何明示或暗示的保证，包括但不限于其有效性、准确性、持续性、有用性、不中断、无错误、及时性等。</p>
    <p class="tex-indent">5.
      焱十一仅是您获取交易拍卖信息、对拍卖会进行直播、国内外代拍的场所，拍卖标的信息均由拍卖公司提供，焱十一没有义务且无法控制拍卖标的的品质、安全、合法性、拍卖标的信息的真实性或准确性以及交易各方履行其在相关协议中各项义务的能力。因此，您应自行谨慎判断相关拍卖标的及/或其信息的真实性、合法性和有效性，并自行承担因此产生的责任与损失。
    </p>
    <p class="tex-indent">6.
      您明确同意在使用焱十一服务所存在的风险将完全由您自己承担。焱十一不参与您和拍卖公司之间的实际交易，对您与拍卖公司之间的交易不作任何担保。如您与拍卖公司发生争议，并申请由焱十一协助的，焱十一可协助解决您与拍卖公司在拍卖过程中可能产生的争议。
    </p>
    <p class="tex-indent">7. 除非法律法规明确要求，焱十一没有义务对您的注册数据、交易商品信息、交易行为以及与交易有关的其它事项进行事先审查。</p>
    <p class="tex-indent">8. 焱十一有权于任何时间暂时或永久修改或终止本服务（或其任何部分），而无论其通知与否，焱十一对用户和任何第三人均无需承担任何责任。</p>
    <p class="tex-indent">9. 终止服务</p>
    <p class="tex-indent-o">
      您同意焱十一得基于其自行之考虑，因任何理由，包含但不限于长时间（超过一年）未使用，或焱十一认为您已经违反本服务协议的文字及精神，终止您的密码、帐号或本服务之使用（或服务之任何部分），并将您在本服务内任何内容加以移除并删除。您同意依本服务协议任何规定提供之本服务，无需进行事先通知即可中断或终止，您承认并同意，焱十一可立即关闭或删除您的帐号及您帐号中所有相关信息及文件，及/或禁止继续使用前述文件或本服务。此外，您同意若本服务之使用被中断或终止或您的帐号及相关信息和文件被关闭或删除，焱十一对您或任何第三人均不承担任何责任。
    </p>
    <h3>五、知识产权和其他合法权益（包括但不限于名誉权、商誉权）</h3>
    <p class="tex-indent">1.
      对于您上传到焱十一上可公开获取区域的任何内容，您同意焱十一在全世界范围内具有免费的、永久性的、不可撤销的、非独家的和完全再许可的权利和许可，以使用、复制、修改、改编、出版、翻译、据以创作衍生作品、传播、表演和展示此等内容（整体或部分），和/或将此等内容编入当前已知的或以后开发的其他任何形式的作品、媒体或技术中。
    </p>
    <p class="tex-indent">2. 焱十一拥有焱十一服务所有资料的版权。焱十一所有的产品、技术与所有程序均属于焱十一的知识产权。</p>
    <p class="tex-indent">3. “焱十一”及相关图形等为焱十一的注册商标。未经焱十一书面许可，任何人不得擅自（包括但不限于：以非法的方式复制、传播、展示、镜像、上载、下载）使用。否则，焱十一将依法追究法律责任。
    </p>
    <h3>六、其他</h3>
    <p class="tex-indent">1. 本协议的订立、执行和解释及争议的解决均应适用中华人民共和国法律。</p>
    <p class="tex-indent">2. 如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方均可向焱十一所在地北京市经济技术开发区人民法院提起诉讼。</p>
    <p class="tex-indent">3. 焱十一未行使或执行本服务协议任何权利或规定，不构成对前述权利或权利之放弃。</p>
    <p class="tex-indent">4. 如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。</p>
    <p class="tex-indent">5.
      当您通过焱十一帐号登录、使用第三方产品或服务时，在遵守本协议基础上，您同时需要遵守该第三方产品或服务的具体使用规则，例如：当您使用第三方物流运输服务时，您需要同时遵守该物流运输服务提供方的相关服务条款。</p>
    <p class="tex-indent">请您在发现任何违反本服务协议之情形时，通知焱十一。您可以通过如下联络方式同焱十一联系：</p>
    <p class="tex-indent">PC端：【个人中心】-【意见反馈】或【我的】-【客服中心】</p>
    <p class="tex-indent">APP端：【我的】-【设置】-【意见反馈】或【我的】-【客服中心】</p>
    <a href="javascript:;" @click="book()" class="book">使用焱十一前必读</a>

    <el-dialog title="免责声明" :visible.sync="centerDialogVisible" width="30%" center>
      <p class="tex-indent">焱十一提醒您：在您使用焱十一前，请您务必仔细阅读并透彻理解本声明。您可以选择不使用焱十一，但如果您使用焱十一，您的使用行为将被视为对本声明全部内容的认可。</p>
      <p class="tex-indent">1. 焱十一为技术服务平台，任何情形下，焱十一并非交易的参与方，既不是竞买人也不是拍卖公司。</p>
      <p class="tex-indent">2.
        在焱十一展示的拍卖会信息及拍卖标的资料（包括但不限于文字、图片、视频、来源、鉴定结果、评价等）均由拍卖公司自行提供，竞买人应自行判断拍卖标的的优劣、真伪等，并对竞拍行为负责。焱十一不参与对任何拍卖标的的描述、评估、鉴定等，亦不对拍卖公司的任何口头、书面陈述或上传的线上信息及拍卖标的的真实性、合法性做任何明示或暗示的担保，或对此承担任何责任。
      </p>
      <p class="tex-indent">3.
        实地查看拍卖标的是竞买人的法定权利。如果竞买人在拍卖标的展示的时间内未查看拍卖标的，视为其自愿放弃这一权利，愿意以拍卖标的的现状参与竞拍。竞买人充分了解并自愿承担未实地查看拍品的风险，因竞买人未实地查看拍标的而产生的法律风险由竞买人自行承担，竞买人不得再以拍卖标的存在实物瑕疵为由提出异议。
      </p>
      <p class="tex-indent">4.
        通过焱十一成功竞得的拍卖标的的交割，包括拍卖标的的包装、运输、领取等一切事项，均在竞买人和拍卖公司及包装运输服务提供方之间进行，焱十一不介入任何拍卖标的的交割事务。焱十一对拍卖公司或竞买人推荐的包装及运输服务提供方所造成的一切错误、遗漏、损坏或灭失等，不承担任何责任。
      </p>
      <p class="tex-indent">5.
        焱十一所提供的拍卖公司网上预展、拍卖结果信息查询服务，目的在于更好地为拍卖公司及艺术品爱好者提供信息服务，所发布信息均来源于各拍卖公司，焱十一不对其真实性和准确性做任何明示或暗示的担保。</p>
      <p class="tex-indent">6.
        本平台上的竞拍服务将按“现状”和按“可得到”的状态提供，焱十一将在现有技术的基础上尽最大努力提供相应的安全措施以保障服务安全和正常运行。但基于技术或由于可能存在的计算机病毒、网络通讯故障、浏览器兼容问题、系统维护等方面的因素以及可能发生的不可抗力，焱十一在此明确声明对技术服务不作任何明示或暗示的保证，包括但不限于：对服务的可适用性、没有错误或疏漏、持续性、准确性、可靠性、适用于某一特定用途。
      </p>
      <p class="tex-indent">7. 因拍卖公司或竞买人自身的操作而造成的损失，由拍卖行或竞买人自行承担由该行为而直接或者间接导致的民事或刑事法律责任。如因此给焱十一造成损失的，用户还应向焱十一承担赔偿责任。</p>
      <p class="tex-indent">8.
        拍卖公司或竞买人应遵守中国的政策和法规，依法纳税，自觉杜绝利用平台之便利以任何形式进行任何违禁、违法或违规的货品或资金交易。用户因违反国家政策和法规造成的法律责任和经济损失由用户自行承担。如因此对焱十一平台造成损失的，焱十一保留追责的权利。
      </p>
      <p class="tex-indent">9. 未经本平台授权，任何单位及个人不得转载、摘编或以其它方式使用本平台上展示的所有作品。违反者，本平台将依法追究其相关法律责任。</p>
      <p class="tex-indent">10.焱十一里的所有知识产权归北京艺得在线科技有限公司所有。任何假冒、仿制本平台以及其它侵害本平台知识产权的行为，北京艺得在线科技有限公司保留追究其法律责任的权利。</p>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data () {
    return {
      centerDialogVisible: false
    }
  },
  methods: {
    book () {
      this.centerDialogVisible = true
    }
  }
}
</script>
<style lang="less" scoped>
.view-men {
  text-align: left;
  padding-left: 20px;
  padding-top: 30px;
  padding-right: 20px;
  .tex-indent {
    text-indent: 2em;
  }
  .tex-indent-o {
    text-indent: 4em;
  }
  .book {
    color: #333;
    text-decoration: underline;
  }
}
</style>
